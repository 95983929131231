import React from 'react';
import Hero from './Hero';
import Team from './Team';
import Footer from "./Footer";
import NavbarAll from "./NavbarAll";

const OtherRoomDetails = () => {


    return (
        <>
            <NavbarAll/>
       
            <Hero />
            <Team />
            <Footer/>
        </>
    );
};

export default OtherRoomDetails;
