import React, { useState, useRef } from "react";

const FormElementSelect = ({children}) => {
  return (
    <section className='py-12 dark:bg-dark'>
      <div className='container-fluit'>
        <div className='flex flex-wrap'>
          <DefaultColumn>
            <CustomSelectInput />
          </DefaultColumn>

          {/* <DefaultColumn>
            <SelectCountry />
          </DefaultColumn> */}

        </div>
      </div>
    </section>
  )
};

// export default FormElementSelect;

const DefaultColumn = ({ children }) => {
  return (
    <div className='w-full md:w-1/2 lg:w-1/3'>
      <div className=''>{children}</div>
    </div>
  )
}


const SelectCountry = ({addclass}) => {
  return (
    <>
      
      <div className='relative z-20'>
        
              <select className={`relative z-20 w-full appearance-none border border-stroke dark:border-dark-3 py-[10px] px-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2
        ${addclass}`}>
          <option value='' className='dark:bg-dark-2'>USA</option>
          <option value='' className='dark:bg-dark-2'>UK</option>
          <option value='' className='dark:bg-dark-2'>Canada</option>
        </select>
        <span className='absolute top-1/2 right-4 z-10 -translate-y-1/2'>
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity={0.8}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                fill="#9CA3AF"
              />
            </g>
          </svg>
        </span>
        <span className="absolute top-1/2 right-4 z-20 -translate-y-1/2 pointer-events-none">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity={0.8}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
              fill="#9CA3AF"
            />
          </g>
        </svg>
      </span>
      </div>
    </>
  )
}

const SelectSub = () => {
    return (
        <>
            <div data-testid="FlightSearchPAXSelection__AdultsMinusButton" class="sc-cnTzU jjODtM"><svg focusable="false" color="inherit" fill="currentcolor" aria-hidden="true" role="presentation" viewBox="0 0 150 150" preserveAspectRatio="xMidYMid meet" width="24px" height="24px" class="sc-bdVaJa bNehGJ sc-jGFFOr bjLvPu"><path d="M75 0C33.6 0 0 33.6 0 75s33.6 75 75 75 75-33.6 75-75S116.4 0 75 0zm33.6 82.8H41.4c-5 0-9-4-9-9s4-9 9-9h67.2c5 0 9 4 9 9s-4 9-9 9z">
            </path>
            </svg>
            </div>
        </>
    );
}


const SelectAdd = () => {
    return (
        <>
            <div class="sc-cnTzU jjODtM">
                <svg focusable="false" color="inherit" fill="currentcolor" aria-hidden="true" role="presentation" viewBox="0 0 150 150" preserveAspectRatio="xMidYMid meet" width="24px" height="24px" class="sc-bdVaJa bNehGJ sc-dOkuiw cFSprK"><path d="M75 0C33.6 0 0 33.6 0 75s33.6 75 75 75 75-33.6 75-75S116.4 0 75 0zm33.6 82.8H84.9v25.8c0 4.9-5.1 8.8-10 8.8s-10-3.9-10-8.8V82.8H41.4c-5 0-9-4-9-9s4-9 9-9h23.5V41.4c0-4.8 5.2-8.8 10-8.8s10 3.9 10 8.8v23.5h23.7c5 0 9 4 9 9s-4 8.9-9 8.9z">
            </path>
            </svg>
            </div>
        </>
    );
}

const ListItem = ({ title, value, setValue }) => {
    const handleSub = () => {
        const newvalue = value - 1;
        setValue(newvalue);
    };
    const handleAdd = () => {
        const newvalue = value + 1;
        setValue(newvalue);
    };
    return (
        <>
            <div className='flex justify-between rounded-md p-2 '>
           <div>{title}</div>
           <div  className='flex justify-between dark:bg-dark-2'>
                    <span onClick={handleSub}>
                        <SelectSub/>
                    </span>
                    <span className='px-3'>
                        {value}
                    </span>
                    <span  onClick={handleAdd}>
                        <SelectAdd />
                    </span>
                </div>
                </div>
        </>
    );
}
const SelectPrimpt = ({ addclass }) => {
    const [inputValue, setInputValue] = useState(1);
    const [inputValue1, setInputValue1] = useState(1);
    const [inputValue2, setInputValue2] = useState(1);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    return (
        <div className='relative z-20'>
            {/* Input field to toggle dropdown */}
            <div 
                className={`flex justify-between items-center py-2 px-4 cursor-pointer
                    ${addclass}`} 
                onClick={toggleDropdown}
            >
                <input
                    type="text"
                    readOnly
                    placeholder="Select Country"
                    className="w-full bg-transparent outline-none cursor-pointer"
                />
                {/* Dropdown icon */}
                <span className="ml-2">
                    <svg 
                        className={`w-5 h-5 transform transition-transform duration-200 ${dropdownOpen ? 'rotate-0' : 'rotate-0'}`}
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24" 
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </span>
            </div>

            {/* Dropdown content */}
            {dropdownOpen && (
                <div className="mt-2 absolute w-full appearance-none border border-gray-300 bg-white rounded-md shadow-lg">
                    <ListItem title="USA" value={inputValue} setValue={setInputValue} />
                    <ListItem title="UK" value={inputValue1} setValue={setInputValue1} />
                    <ListItem title="Canada" value={inputValue2} setValue={setInputValue2} />
                </div>
            )}
        </div>
    );
};
const SelectPrimpt1 = ({ addclass }) => {
    const [inputValue, setInputValue] = useState(1);
    const [inputValue1, setInputValue1] = useState(1);
    const [inputValue2, setInputValue2] = useState(1);
  return (
    <>
      
      <div className='relative z-20'>
        
              <select className={`relative z-20 w-full appearance-none border border-stroke dark:border-dark-3 py-[10px] px-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2
        ${addclass}`}>
          <option value='' className='block w-full dark:bg-dark-2'> <ListItem titel="USA" value={inputValue} setValue={setInputValue}/> </option>
          <option value='' className='dark:bg-dark-2'> <ListItem titel="UK" value={inputValue1} setValue={setInputValue1}/> </option>
          <option value='' className='dark:bg-dark-2'> <ListItem titel="Canada" value={inputValue2} setValue={setInputValue2}/> </option>
        </select>
        <span className='absolute top-1/2 right-4 z-10 -translate-y-1/2'>
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity={0.8}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                fill="#9CA3AF"
              />
            </g>
          </svg>
        </span>
        <span className="absolute top-1/2 right-4 z-20 -translate-y-1/2 pointer-events-none">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity={0.8}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
              fill="#9CA3AF"
            />
          </g>
        </svg>
      </span>
      </div>
    </>
  )
}



const NameInput = () => {
  return (
    <>
      <div className='relative'>
        <input
          type='text'
          placeholder='Devid Jhon'
          className='w-full bg-transparent rounded-md border border-stroke dark:border-dark-3 py-[10px] pr-3 pl-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
        >
             <select className='relative z-20 w-full appearance-none rounded-md border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'>
          <option value='' className='dark:bg-dark-2'>USA</option>
          <option value='' className='dark:bg-dark-2'>UK</option>
          <option value='' className='dark:bg-dark-2'>Canada</option>
        </select>
        <span className='absolute top-1/2 right-4 z-10 -translate-y-1/2'>
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity={0.8}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                fill="#9CA3AF"
              />
            </g>
          </svg>
        </span>
        </input>
        <span className='absolute top-1/2 left-4 -translate-y-1/2'>
          <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.72 12.886a4.167 4.167 0 0 1 2.947-1.22h6.666a4.167 4.167 0 0 1 4.167 4.167v1.666a.833.833 0 1 1-1.667 0v-1.666a2.5 2.5 0 0 0-2.5-2.5H6.667a2.5 2.5 0 0 0-2.5 2.5v1.666a.833.833 0 1 1-1.667 0v-1.666a4.17 4.17 0 0 1 1.22-2.947ZM10 3.333a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-4.166 2.5a4.167 4.167 0 1 1 8.333 0 4.167 4.167 0 0 1-8.333 0Z"
              opacity={0.8}
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9CA3AF"
            />
          </svg>
        </span>
      </div>


    </>
  )
}

// Custom Select Component
const CustomSelectInput = ({addclass}) => {
  const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility
  const [inputValue, setInputValue] = useState(''); // State for the input value
  const [selectedValue, setSelectedValue] = useState(''); // State for the selected value
  const dropdownRef = useRef(null); // Reference for detecting clicks outside the dropdown

  // Handle click outside of the dropdown to close it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  // Attach and remove click event listener on component mount/unmount
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle option select
  const handleSelect = (value) => {
    setSelectedValue(value);
    setInputValue(value); // Set input value to selected option
    setShowDropdown(false); // Close dropdown after selection
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      {/* Input field */}
      <input
        type="text"
        placeholder="Select a country or type"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setShowDropdown(true)}
              className={`w-full bg-white border border-stroke dark:border-dark-3 py-[10px] pr-12 pl-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2
            ${addclass}`}
      />

      {/* Custom Dropdown */}
      {showDropdown && (
        <div className="absolute z-20 w-full mt-1 bg-white border border-stroke dark:border-dark-3 rounded-md shadow-lg">
          {/* Dropdown Header */}
          <div className="px-4 py-2 bg-gray-100 text-dark-6 dark:bg-dark-2 dark:text-white font-semibold">
            Select Country
          </div>

          {/* Dropdown Options */}
          <ul className="py-2">
            <li
              className="px-4 py-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-dark-3"
              onClick={() => handleSelect('USA')}
            >
              USA
            </li>
            <li
              className="px-4 py-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-dark-3"
              onClick={() => handleSelect('UK')}
            >
              UK
            </li>
            <li
              className="px-4 py-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-dark-3"
              onClick={() => handleSelect('Canada')}
            >
              Canada
            </li>
          </ul>
        </div>
      )}

      {/* Dropdown Icon */}
      <span className="absolute top-1/2 right-4 z-20 -translate-y-1/2 pointer-events-none">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity={0.8}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
              fill="#9CA3AF"
            />
          </g>
        </svg>
      </span>

      {/* Left Icon */}
      <span className="absolute top-1/2 left-4 -translate-y-1/2">
        <svg
          width={20}
          height={20}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.72 12.886a4.167 4.167 0 0 1 2.947-1.22h6.666a4.167 4.167 0 0 1 4.167 4.167v1.666a.833.833 0 1 1-1.667 0v-1.666a2.5 2.5 0 0 0-2.5-2.5H6.667a2.5 2.5 0 0 0-2.5 2.5v1.666a.833.833 0 1 1-1.667 0v-1.666a4.17 4.17 0 0 1 1.22-2.947ZM10 3.333a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-4.166 2.5a4.167 4.167 0 1 1 8.333 0 4.167 4.167 0 0 1-8.333 0Z"
            opacity={0.8}
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#9CA3AF"
          />
        </svg>
      </span>
    </div>
  );
};

export { FormElementSelect, CustomSelectInput,DefaultColumn, SelectCountry, SelectPrimpt };
// export default CustomSelectInput;

