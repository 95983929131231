
import React from "react";
import img1 from "../assets/images/almosafer1.jpg";
import img2 from "../assets/images/almosafer2.webp";
import img3 from "../assets/images/almosafer3.webp";
import img4 from "../assets/images/almosafer4.webp";
import {BadegsBlue, Prograph} from "./element_component/BadegsBlue";

export default function HotelList() {
  const hotels = [
    {
      image:img1,
      hotelName: 'SLS Dubai Hotel & Residences',
      location: 'Marasi Drive',
      price: 364,
      rating: 8.8,
      stars: 5,
    },
    {
      image:img3,
      hotelName: 'Radisson Blu Hotel, Dubai Canal View',
      location: 'Marsi Drive',
      price: 244,
      rating: 9,
      stars: 5,
    },
    {
      image:img2,
      hotelName: 'The Lana - Dorchester Collection',
      location: 'Marasi Drive, Burj Khalifa District',
      price: 700,
      rating: 8,
      stars: 5,
    },
    {
      image:img3,
      hotelName: 'Radisson Blu Hotel, Dubai Canal View',
      location: 'Marsi Drive',
      price: 244,
      rating: 9,
      stars: 5,
    },
    {
      image:img4,
      hotelName: 'The Lana - Dorchester Collection',
      location: 'Marasi Drive, Burj Khalifa District',
      price: 700,
      rating: 8,
      stars: 5,
    },
    {
      image:img1,
      hotelName: 'SLS Dubai Hotel & Residences',
      location: 'Marasi Drive',
      price: 364,
      rating: 8.8,
      stars: 5,
    },
  ];

  return (
    <div className="container-fluit mx-auto px-6 pb-8">
      <h1 className="my-6 text-2xl font-semibold">Al Habtoor City, Sheikh Zayed Road</h1>
      <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {hotels.map((hotel, index) => (
          <HotelCard key={index} {...hotel} />
        ))}
      </div>
    </div>
  );
}

const HotelCard = ({
  image,
  hotelName,
  location,
  price,
  rating,
  stars,
}) => {
  return (
    <div className="border rounded-lg overflow-hidden bg-white shadow-md transition-transform hover:shadow-lg hover:scale-105">
      {/* Hotel Image */}
      <div className="relative">
        <img src={image} alt={hotelName} className="w-full h-48 object-cover" />
        <div className="absolute top-3 right-3 bg-green-600 text-white rounded-full px-2 py-1 text-sm">
          {rating}
        </div>
      </div>

      {/* Hotel Info */}
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2">{hotelName}</h3>
        <div className="flex items-center mb-2">
          {/* <span className="bg-blue-100 text-blue-500 px-2 py-1 text-xs rounded-full">
           Hotel
          </span> */}
          <BadegsBlue titel="Hotel"/>
          <span className="ml-2 text-yellow-400">{'⭐'.repeat(stars)}</span>
        </div>
        <Prograph titel={location} classname="text-sm mb-4">
        {/* <p className="text-sm text-gray-500 mb-4">
          <span className="inline-block mr-2"> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 inline"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a1 1 0 01-.576-.183l-5-3.333A1 1 0 014 13.667V6.333a1 1 0 01.424-.818l5-3.333a1 1 0 011.152 0l5 3.333a1 1 0 01.424.818v7.334a1 1 0 01-.424.818l-5 3.333A1 1 0 0110 18zM5 12.611l5 3.334 5-3.334V7.39l-5-3.333-5 3.333v5.222z"
                clipRule="evenodd"
              />
          </svg>
          </Prograph>
          {/* </span>
          {location}
        </p> */}

        <div className="flex justify-between items-center">
          <div className="text-lg font-bold text-teal-600">${price}</div>
          <div className="text-xs text-gray-500">(incl. VAT)</div>
        </div>
      </div>

      {/* Button */}
      <div className="p-4">
        <a
          href="#"
          className="block bg-red-500 text-white text-center rounded-full py-2 transition-colors hover:bg-red-600"
        >
          See rooms
        </a>
      </div>
    </div>
  );
};

