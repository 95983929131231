// src/App.js
import React from 'react';

const filters = [
  { id: 1, name: "7+ Good", active: true },
  { id: 2, name: "Palm Jumeirah", active: true },
  { id: 3, name: "Jumeirah Beach Residence", active: true },
];

const hotels = [
  {
    id: 1,
    name: "FIVE Palm Jumeirah Dubai",
    type: "Resort",
    location: "The Palm",
    rating: 8.2,
    reviews: 981,
    distance: "7 km",
    image: "https://via.placeholder.com/300x200",
  },
];

const Room = () => {
  return (
    <div className="bg-gray-200 p-4">
      {/* Header Section */}
      {/* <header className="flex justify-between items-center p-4 bg-white shadow-sm">
        <h1 className="text-xl font-bold">Almosafer</h1>
        <button className="bg-blue-500 text-white px-4 py-2 rounded">Sign in</button>
      </header> */}

      {/* Filters Section */}
      <div className="flex justify-between items-center mt-6 mb-4">
        <span className="text-sm font-semibold">58 stays found in Dubai</span>
        <button className="bg-green-200 text-green-800 px-3 py-1 rounded-full flex items-center">
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-4.896-4.896a1.5 1.5 0 00-2.12 0l-7.728 7.728a12 12 0 0016.8 0l-1.056-1.056a1.5 1.5 0 00-1.056-.456h-.24zm3.632-7.28a3 3 0 00-4.24 0l-1.12 1.12 4.24 4.24 1.12-1.12a3 3 0 000-4.24zm-7.56 7.56l-1.12-1.12 8.48-8.48 1.12 1.12-8.48 8.48z"></path>
          </svg>
          Go to Map view
        </button>
      </div>

      {/* Active Filters */}
      <div className="flex flex-wrap gap-2 mb-4">
        {filters.map((filter) => (
          <button
            key={filter.id}
            className={`px-3 py-1 rounded-full text-sm border ${
              filter.active ? "bg-blue-200 text-blue-800" : "bg-gray-200 text-gray-800"
            }`}
          >
            {filter.name}
          </button>
        ))}
      </div>

      {/* Sorting Options */}
      <div className="flex justify-between items-center mb-4">
        <span>Sort by :</span>
        <div className="flex space-x-2">
          <button className="bg-gray-200 px-3 py-1 rounded">Most popular</button>
          <button className="bg-gray-200 px-3 py-1 rounded">Lowest price</button>
          <button className="bg-gray-200 px-3 py-1 rounded">Highest guest rating</button>
          <button className="bg-gray-200 px-3 py-1 rounded">Distance</button>
        </div>
      </div>

      {/* Hotel Listings */}
      <div className="grid grid-cols-1 gap-4">
        {hotels.map((hotel) => (
          <div key={hotel.id} className="bg-white p-4 rounded-lg shadow-md flex">
            <img src={hotel.image} alt={hotel.name} className="w-48 h-48 object-cover rounded" />
            <div className="ml-4 flex flex-col justify-between">
              <div>
                <h2 className="text-lg font-semibold">{hotel.name}</h2>
                <p className="text-sm text-gray-500">{hotel.type} - {hotel.location}</p>
                <p className="text-sm text-gray-500">{hotel.distance} from city center of Dubai</p>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-green-600 font-bold text-lg">{hotel.rating}</span>
                <span className="text-gray-500 text-sm">{hotel.reviews} Guest reviews</span>
              </div>
              <button className="mt-2 bg-blue-500 text-white px-3 py-2 rounded-md w-full">
                Check availability
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Room;

