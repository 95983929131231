import React, { useState } from "react";
import Checkbox2 from './Checkbox2';
import DatePicker from './DatePicker';
import { FormElementSelect, CustomSelectInput, DefaultColumn, SelectCountry, SelectPrimpt} from './FormElementSelect';

const TabHeader = () => {
  const [open, setOpen] = useState("home");
  const [num, setNum] = useState(1);

  const handleTabOpen = (tabCategory) => {
    setOpen(tabCategory);
  };

  return (
    <>
      <section className="bg-header-primary dark:bg-dark px-8">
        <div className="container-fluit">
          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="mb-14 w-full">
                <div className="flex gap-5 flex-col justify-between flex-wrap rounded-lg pt-8 dark:border-dark-3 sm:flex-row">
                  <div>
                  <a
                    onClick={() => handleTabOpen("home")}
                    className={`cursor-pointer rounded-md px-4 py-3 text-sm font-medium md:text-base lg:px-6 text-white ${
                      open === "home"
                        ? "bg-nav-tab-color"
                        : "dark:text-dark-6 dark:hover:text-white"
                    }`}
                  >
                    Home
                  </a>
                  <a
                    onClick={() => handleTabOpen("about")}
                    className={`cursor-pointer rounded-md px-4 py-3 text-sm font-medium md:text-base lg:px-6 text-white ${
                      open === "about"
                        ? "bg-nav-tab-color"
                        : "dark:text-dark-6 dark:hover:text-white"
                    }`}
                  >
                    About Us
                  </a>
                  <a
                    onClick={() => handleTabOpen("team")}
                    className={`cursor-pointer rounded-md px-4 py-3 text-sm font-medium md:text-base lg:px-6 ${
                      open === "team"
                        ? "bg-nav-tab-color"
                        : "dark:text-dark-6 dark:hover:text-white"
                    }`}
                  >
                    Our Team
                  </a>
                  </div>
                 <div className="sm:ps-24 md:ps-64 flex flex-1 justify-between">
                     <Checkbox2 />
                     <Checkbox2 />
                     <Checkbox2 />
                </div>
                </div>
                
                  
                <TabContent
                  details=" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia nisi, doloribus nulla cumque molestias corporis eaque harum vero! Quas sit odit optio debitis nulla quisquam, dolorum quaerat animi iusto quod."
                  tabCategory="home"
                  open={open}
                >
                  <div className='container-fluit'>
                    <div className='flex justify-between flex-wrap gap-1'>
                      <div className='w-full md:w-1/2 lg:w-1/3'>
                    <CustomSelectInput addclass="rounded-t-md mb-1"/>
                    <CustomSelectInput addclass="rounded-b-md"/>
                      </div>
                      
                      <div className='flex flex-wrap gap-1'>
                        <DatePicker />
                        {/* <DatePicker /> */}
                    </div>
                      <div className='w-full md:w-1/3 lg:w-1/4'>
                    <SelectCountry addclass="rounded-t-md mb-1"/>
                    <SelectPrimpt addclass="rounded-b-md bg-white"/>
                      </div>
                      <div className='sm:w-full md:w-1/3 lg:w-auto'>
                        <div className="text-center p-1 bg-red-600 text-white h-24 rounded-sm d-none d-md-block">
                          <button data-testid="FlightSearchBox__SearchButton" type="button"
                            className="h-full w-full px-8 btn btn-primary">
                            <svg
                              focusable="false"
                              color="inherit"
                              fill="currentcolor"
                              aria-hidden="true"
                              role="presentation"
                              viewBox="0 0 24 24"
                              preserveAspectRatio="xMidYMid meet"
                              width="24px"
                              height="24px"
                              className="mx-auto mb-2">
                              <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                              <path d="M0 0h24v24H0z" fill="none"></path>
                            </svg>
                            <span className="">Search flights</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    </div>
                </TabContent>
                <TabContent
                  details=" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia nisi, doloribus nulla cumque molestias corporis eaque harum vero! "
                  tabCategory="about"
                  open={open}
                >
                  <FormElementSelect />
                </TabContent>
                <TabContent
                  details="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia nisi, doloribus nulla cumque molestias corporis eaque harum vero! Quas sit odit optio debitis nulla quisquam, dolorum quaerat animi iusto quod.

              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit mollitia nam eligendi reprehenderit reiciendis saepe laboriosam maiores voluptas. Quo, culpa amet fugiat ipsam sed quod hic, veritatis ducimus recusandae repellat quasi eaque, suscipit praesentium totam?"
                  tabCategory="team"
                  open={open}
                >
                  {/* <FormElementSelect /> */}
                </TabContent>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TabHeader;

const TabContent = ({ open, tabCategory, details,children }) => {
  return (
    <div>
      <div
        className={`py-8 text-base leading-relaxed text-body-color dark:text-dark-6 ${
          open === tabCategory ? "block" : "hidden"
        } `}
      >
        {/* {details} */}
      <div className='w-full'>{children}</div>
    
      </div>
      
    </div>
  );
};
