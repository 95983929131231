import React from "react";
import { FaWifi, FaShuttleVan, FaSwimmer, FaConciergeBell, FaPlayground, FaSnowflake, FaSmokingBan, FaLeaf, FaWheelchair } from "react-icons/fa";
// npm install react-icons
import {Prograph, AvatarChar} from './element_component/BadegsBlue';

const amenitiesTopIcons = [
  { icon: <FaWifi />, label: "Wi-Fi" },
  { icon: <FaShuttleVan />, label: "Shuttle to airport" },
  { icon: <FaSwimmer />, label: "Swimming pool" },
  { icon: <FaConciergeBell />, label: "Front desk" },
  // { icon: <FaPlayground />, label: "Playground" },
  { icon: <FaSnowflake />, label: "Air conditioning" },
  { icon: <FaSmokingBan />, label: "Non-smoking rooms" },
  { icon: <FaLeaf />, label: "Smoking area" },
];

const amenitiesDetails = [
  {
    category: "Internet Access",
    items: ["Wi-Fi", "Restaurant"],
    icon: <FaWifi />
  },
  {
    category: "Food & drink",
    items: ["Restaurant", "Room service", "Housekeeping"],
    icon: <FaConciergeBell />
  },
  {
    category: "Front desk services",
    items: ["Front desk", "24 hour", "Room service", "Housekeeping"],
    icon: <FaConciergeBell />
  },
  {
    category: "Accessibility",
    items: ["Wheelchair accessible", "Toilet with grab rails", "Roll-in shower"],
    icon: <FaWheelchair />
  },
  {
    category: "Pool & beach",
    items: ["Swimming pool", "Outdoor pool", "Pool bar"],
    icon: <FaSwimmer />
  },
  {
    category: "Internet Access",
    items: ["Wi-Fi"],
    icon: <FaWifi />
  },
  {
    category: "Food & drink",
    items: ["Restaurant"],
    icon: <FaConciergeBell />
  },
  {
    category: "Front desk services",
    items: ["Front desk", "24 hour", "Room service", "Housekeeping"],
    icon: <FaConciergeBell />
  },
  {
    category: "Accessibility",
    items: ["Wheelchair accessible", "Toilet with grab rails", "Roll-in shower"],
    icon: <FaWheelchair />
  },
  {
    category: "Pool & beach",
    items: ["Swimming pool", "Outdoor pool", "Pool bar"],
    icon: <FaSwimmer />
  },
];

// Top Icons Component
const TopAmenities = () => {
  return (
    <div className="flex flex-wrap justify-start items-center space-x-4 mb-6">
      {amenitiesTopIcons.map((amenity, index) => (
        <div key={index} className="flex items-center space-x-1 text-green-600 text-sm">
          <span className="text-lg">{amenity.icon}</span>
          <span>{amenity.label}</span>
        </div>
      ))}
    </div>
  );
};

// Detailed Amenities Component
const DetailedAmenities = () => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-8 mt-4">
      {amenitiesDetails.map((amenity, index) => (
        <div key={index}>
          <h3 className="text-lg font-semibold flex items-center space-x-2">
            <span>{amenity.icon}</span>
            <span>{amenity.category}</span>
          </h3>
          <ul className="mt-2 text-sm text-gray-600">
            {amenity.items.map((item, i) => (
              <li key={i} className="flex items-start mb-1 space-x-1">
                {/* <span className="bg-blue-primary-badges py-1 px-2 border rounded-full">✓</span>
                <span>{item}</span> */}
                <Prograph titel={item} classname="text-sm">
                  <AvatarChar titel="✓" classname="bg-blue-primary-badges"/>
                </Prograph>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

// Amenities Section Component
const AmenitiesSection = () => {
  return (
    <div className="container-fluit m-4 p-6 bg-white rounded-2xl">
      <h2 className="text-2xl font-bold mb-2">Amenities</h2>
      <p className="text-gray-600 mb-4">Amenities for Paramount Hotel Dubai</p>
      <div className="border border-gray-300 p-4 rounded-lg">
      {/* Top Icons */}
        <TopAmenities />
        <hr/>

      {/* Detailed Amenities */}
        <DetailedAmenities />
        </div>
    </div>
  );
};

export default AmenitiesSection;
