import React from 'react'

const ButtonHeader = () => {
  return (
    <div className="">
              <nav className="flex w-full items-center ">
                  <a href="/en/hotels-home" data-testid="Header__HotelsNavigationTab" className="flex p-3 rounded-t bg-header-primary">
                      <svg stroke="#fff" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" color="" size="16">
                          <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M2.25 16v-2.25a2 2 0 0 1 2-2h15.5a2 2 0 0 1 2 2V16m-19.5 3.5v-12a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3v12m0-3.25H2.25m2.25-4.5v-1a2 2 0 0 1 2-2H10a2 2 0 0 1 2 2m0 0v1m0-1a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v1">
                          </path>
                      </svg>
                      <span className="px-2">Stays</span>
                  </a>
                  <a href="/en/flights-home" data-testid="Header__FlightsNavigationTab" className="flex p-3 rounded-t bg-header-secondery">
                      <svg stroke="#fff" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" color="" size="16">
                          <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="m3.76 11.99-1.5-3.75h3l1.5 1.5h3l-1.5-7.5h3l3.75 7.5 4.5.01a2.25 2.25 0 0 1 0 4.5l-4.5.01-3.75 7.5h-3l1.5-7.5h-3l-1.5 1.48h-3l1.5-3.75Z">
                          </path>
                      </svg>
                      <span className="px-2 text-black">Flights</span>
                  </a>
              </nav>
          </div>
  )
}

export default ButtonHeader