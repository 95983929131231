import React, { useState } from "react";
import img1 from "../assets/images/almosafer1.jpg"
import img2 from "../assets/images/almosafer2.webp"
import img3 from "../assets/images/almosafer3.webp"
import img4 from "../assets/images/almosafer4.webp"

const ImageCarousel = () => {
  const images = [
    // "https://via.placeholder.com/600x400?text=Slide+1",
    // "https://via.placeholder.com/600x400?text=Slide+2",
    // "https://via.placeholder.com/600x400?text=Slide+3",
    //   "https://via.placeholder.com/600x400?text=Slide+4",
      img1,
      img2,
      img3,
      img4,
    img1,
      img2,
      img3,
    img4,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleSlides = 4; // Number of visible images at a time

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - visibleSlides ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - visibleSlides : prevIndex - 1
    );
  };

  return (
    <div className="bg-gray-2 relative my-8 w-auto overflow-hidden rounded-lg shadow-lg">
      {/* Images */}
      <div
        className="flex gap-1 bg-gray-2 transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * (100 / visibleSlides)}%)` }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className="w-1/4 h-72 object-cover"
          />
        ))}
      </div>

      {/* Left Arrow */}
      <button
        className="absolute top-1/2 left-5 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-600 transition"
        onClick={prevSlide}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>

      {/* Right Arrow */}
      <button
        className="absolute top-1/2 right-5 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-600 transition"
        onClick={nextSlide}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>

      {/* Dots Indicators */}
      <div className="absolute bottom-5 left-0 right-0 flex justify-center space-x-3">
        {images.slice(0, images.length - visibleSlides + 1).map((_, index) => (
          <span
            key={index}
            className={`w-3 h-3 rounded-full bg-gray-2 ${
              currentIndex === index ? "bg-opacity-100" : "bg-opacity-50"
            }`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;