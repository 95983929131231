import React from "react";

const PageTitle5 = () => {
  return (
    <section className="py-[40px]  px-8">
      <div className="mx-auto">
        <div>
          <h2 className="mb-2 text-4xl sm:text-center md:text-start font-semibold text-white dark:text-white">
            Let’s book your next trip!
          </h2>
          <p className="text-xl sm:text-center md:text-start font-medium text-white dark:text-dark-6">
            Choose from over 1.5 million hotels & 450+ airlines
          </p>
        </div>
      </div>
    </section>
  );
};

export default PageTitle5;
