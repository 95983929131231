
const BadegsBlue =({titel}) => { 
    return (
        <>
         <span className="bg-blue-100 text-blue-500 px-2 py-1 text-xs rounded-full">
            {titel}
          </span>
        </>
    );
}

const Prograph= ({titel, classname, children}) => { 
    return (
        <>
            
        <p className={`text-gray-500 ${classname}`}>
          <span className="inline-block mr-2">
            {children}
          </span>
          {titel}
        </p>
        </>
    );
}


const ButtonRoundIcon= ({titel,children, classname="outline outline-primary px-4 py-2 bg-gray-5"}) => { 
    return (
        <>
        <div className={`flex justify-center w-fit mx-1 items-center rounded-full ${classname}`}>
                <span>{titel}</span><span className="ps-1">{children}</span>
           </div>
        </>
    );
}



const BadegsMark= ({number,classes}) => { 
    return (
        <>
        <div className={`flex justify-center px-4 py-5 w-fit items-center rounded-lg ${classes}`}>
          <span className='text-2xl'>{number}</span>
        </div>
        </>
    );
}

const ItemWithPrograssBar= ({index, label, value, classname=""}) => { 
    return (
        <>
        <div key={index} className="flex items-center justify-between mb-2">
                <span className="text-sm font-medium text-gray-600">{label}</span>
                <div className="flex items-center w-1/2">
                  {/* <div className="bg-gray-200 h-2 w-full rounded-lg relative">
                    <div className={`h-2 rounded-lg ${classname}`}
                      style={{ width: `${(value / 10) * 100}%` }}
                    />
                    </div> */}
                  <ItemPrograssBar value={value} classname={classname} />
                  <span className="ml-2 text-sm text-gray-700">{value}</span>
                </div>
              </div>
        </>
    );
}

const ItemPrograssBar= ({value, classname=""}) => { 
    return (
        <>
          <div className="bg-gray-200 h-2 w-full rounded-lg relative">
            <div className={`h-2 rounded-lg ${classname}`}
                style={{ width: `${(value / 10) * 100}%` }}
              />
          </div>
        </>
    );
}

const MssageSvgMark= ({number,classname}) => { 
    return (
        <>
        <div className={`relative w-14 ${classname}`}>
              <svg color="#129911" viewBox="0 0 60 54" className="w-full h-full">
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M31.039 42.668L19.497 53.563c-.964.91-2.684.304-2.684-.945v-9.95H2.273A2.273 2.273 0 0 1 0 40.395V2.273A2.273 2.273 0 0 1 2.273 0h55.454A2.273 2.273 0 0 1 60 2.273v38.122a2.273 2.273 0 0 1-2.273 2.273H31.04z"
                />
              </svg>
              <span className="absolute inset-0 pb-2 flex items-center justify-center text-xl">{number}</span>
            </div>
        </>
    );
}

const AvatarChar= ({titel, classname=""}) => { 
    return (
        <>
        <div className={`rounded-full w-8 h-8 flex items-center justify-center ${classname}`}>
               {titel}
          </div>
        </>
    );
}

const ButtonRoundedlg= ({titel, classname="px-2 py-1 bg-teal-100 text-teal-600"}) => { 
    return (
        <>
        <button className={`rounded-lg flex justify-center items-center ${classname}`}>
                {titel}
        </button>
        </>
    );
}
export {BadegsBlue, Prograph, ButtonRoundIcon, BadegsMark, ItemWithPrograssBar, MssageSvgMark, ItemPrograssBar, AvatarChar, ButtonRoundedlg};