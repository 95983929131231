import React from "react";
import img1 from "../assets/images/almosafer1.jpg"
import img2 from "../assets/images/almosafer2.webp"
import img3 from "../assets/images/almosafer3.webp"
import img4 from "../assets/images/almosafer4.webp"
import icon01 from "../assets/images/icon-01.svg"
import icon02 from "../assets/images/icon-02.svg"
import icon03 from "../assets/images/icon-03.svg"
import icon04 from "../assets/images/icon-04.svg"

import ImageCarousel from './ImageCarousel';

const Card = () => {
  return (
    <>
      <section className="bg-gray-2 pb-10 pt-8 dark:bg-dark lg:pb-8 lg:pt-[40px]">
        <div className="container-fluit px-8">
           <div className=" mb-8 max-w-[510px] text-start lg:mb-8">
              
              <h3 className="mb-2 text-xl font-bold leading-[1.2] text-dark dark:text-white sm:text-1xl md:text-[30px]">
                What We Offer
              </h3>
              <p className="text-base text-xl text-body-color dark:text-dark-6">
                There are many variations of passages of Lorem Ipsum.
              </p>
          </div>
          
          <ImageCarousel />
          <div className="grid gap-4 mb-8 sm:grid-cols-3 lg:grid-cols-4">
            <SingleCard2
              CardTitle="50+ Best creative website themes & templates"
              titleHref="/#"
              btnHref="/#"
              CardDescription="Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem consectetur adipiscing elit."
              Button="View Details"
              title="Cairo"
              subtitle="Ramses Hilton"
              icon={icon01}
            />
            <SingleCard2
              CardTitle="Creative Card Component designs graphic elements"
              CardDescription="Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem consectetur adipiscing elit."
              Button="View Details"
              title="Dubai"
              subtitle="Atlantis The Royal"
              icon={icon02}
            />
            <SingleCard2
              CardTitle="The ultimate UX and UI guide to card design"
              CardDescription="Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem consectetur adipiscing elit."
              Button="View Details"
              title="Cairo"
              subtitle="Ramses Hilton"
              icon={icon03}
            />
            <SingleCard2
              CardTitle="Creative Card Component designs graphic elements"
              CardDescription="Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem consectetur adipiscing elit."
              Button="View Details"
              title="Dubai"
              subtitle="Atlantis The Royal"
              icon={icon04}
            />
          </div>
          <div className="grid gap-4 sm:grid-cols-3 lg:grid-cols-4">
            <SingleCard
              image={img1}
              CardTitle="50+ Best creative website themes & templates"
              titleHref="/#"
              btnHref="/#"
              CardDescription="Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem consectetur adipiscing elit."
              Button="View Details"
              title="Cairo"
              subtitle="Ramses Hilton"
              icon={icon01}
            />
            <SingleCard
              image={img2}
              CardTitle="Creative Card Component designs graphic elements"
              CardDescription="Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem consectetur adipiscing elit."
              Button="View Details"
              title="Dubai"
              subtitle="Atlantis The Royal"
              icon={icon02}
            />
            <SingleCard
              image={img3}
              CardTitle="The ultimate UX and UI guide to card design"
              CardDescription="Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem consectetur adipiscing elit."
              Button="View Details"
              title="Cairo"
              subtitle="Ramses Hilton"
              icon={icon03}
            />
            <SingleCard
              image={img4}
              CardTitle="Creative Card Component designs graphic elements"
              CardDescription="Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem consectetur adipiscing elit."
              Button="View Details"
              title="Dubai"
              subtitle="Atlantis The Royal"
              icon={icon04}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Card;

const SingleCard = ({
  image,
  Button,
  CardDescription,
  CardTitle,
  titleHref,
  btnHref,
  title,
  subtitle,
  icon,
}) => {
  return (
    <>
      {/*  */}
      <div className="mb-10 overflow-hidden rounded-md bg-gray-2 duration-300 hover:shadow-3 dark:bg-dark-2">
        <div className="relative">
          <img src={image} alt="" className="w-full h-[320px] rounded-md" />
          <div className="absolute bottom-3 w-full text-center text-white">
            <div  className="text-md font-semibold sm:text-[20px] md:text-md lg:text-[22px] xl:text-xl 2xl:text-[22px]"
            >
              {title}
            </div>
            <div>
              {subtitle}
            </div>
          </div>
        </div>
        <div>
          <img src={icon} alt="" className="py-8 px-2" />
        </div>
        <div className="px-2 text-start sm:pb-5 md:pb-2 xl:pb-5">
          <h3>
            <a
              href={titleHref ? titleHref : "/#"}
              className="mb-4 block text-xl font-semibold text-dark dark:text-white sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px]"
            >
              {CardTitle}
            </a>
          </h3>
          <p className="mb-3 text-base leading-relaxed text-body-color dark:text-dark-6">
            {CardDescription}
          </p>

          {/* {Button && (
            <a
              href={btnHref ? btnHref : "#"}
              className="inline-block text-center w-full rounded-full border border-gray-3 px-7 py-2 text-base font-medium text-body-color transition hover:border-primary hover:bg-primary hover:text-white dark:border-dark-3 dark:text-dark-6"
            >
              {Button}
            </a>
          )} */}
        </div>
      </div>
      {/*  */}
    </>
  );
};


const SingleCard2 = ({
  CardDescription,
  CardTitle,
  titleHref,
  title,
  subtitle,
  icon,
}) => {
  return (
    <>
      {/*  */}
      <div className="mb-10 overflow-hidden rounded-md bg-gray-2 duration-300 hover:shadow-3 dark:bg-dark-2">
        <div className="relative">
          <div className="absolute bottom-3 w-full text-center text-white">
            <div  className="text-md font-semibold sm:text-[20px] md:text-md lg:text-[22px] xl:text-xl 2xl:text-[22px]"
            >
              {title}
            </div>
            <div>
              {subtitle}
            </div>
          </div>
        </div>
        <div>
          <img src={icon} alt="" className="py-8 px-2" />
        </div>
        <div className="px-2 text-start sm:pb-5 md:pb-2 xl:pb-5">
          <h3>
            <a
              href={titleHref ? titleHref : "/#"}
              className="mb-4 block text-xl font-semibold text-dark dark:text-white sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px]"
            >
              {CardTitle}
            </a>
          </h3>
          <p className="mb-3 text-base leading-relaxed text-body-color dark:text-dark-6">
            {CardDescription}
          </p>

          {/* {Button && (
            <a
              href={btnHref ? btnHref : "#"}
              className="inline-block text-center w-full rounded-full border border-gray-3 px-7 py-2 text-base font-medium text-body-color transition hover:border-primary hover:bg-primary hover:text-white dark:border-dark-3 dark:text-dark-6"
            >
              {Button}
            </a>
          )} */}
        </div>
      </div>
      {/*  */}
    </>
  );
};
