import React from "react";
import iconwhatsapp from "../assets/images/icon-whatsapp.svg"
import iconemail from "../assets/images/icon-email.svg"
import icon24hour from "../assets/images/icon-24-hour.svg"
import appstoreicon from "../assets/images/appstore-icon.svg"
import almfooterimageen from "../assets/images/alm-footer-image-en.svg"
import googleplayicon from "../assets/images/google-play-icon.svg"
import huaweiiconfrom from "../assets/images/huawei-icon.svg"
import Almosaferqrcode from "../assets/images/Almosafer-qr-code.svg"

const UpFooter = () => {
  return (
    <>
          <section className="bg-gray-2 dark:bg-dark lg:pt-[40px]">
              <div className="container-fluit px-8 flex">
                  <div className="px-2 mt-auto pt-12 text-start sm:py-7 md:py-4 xl:py-7 flex-1">
                      <h3>
                          <span className="block sm:text-md font-semibold text-dark dark:text-white sm:text-[20px] md:text-1xl lg:text-[42px] xl:text-4xl 2xl:text-[42px]">
                              Get the
                          </span>
                      </h3>
                      <h3>
                          <span className="mb-2 block sm:text-md font-semibold text-dark dark:text-white sm:text-[20px] md:text-1xl lg:text-[42px] xl:text-4xl 2xl:text-[42px]">
                               Almosafer app!
                          </span>
                      </h3>
                      <p>
                          Our app has all your hotel needs covered: Secure payment channels, easy 4-step booking process, and sleek user designs. What more could you ask for?
                      </p>
                  </div>
                  <div className="flex flex-1">
                      <div className="px-2 flex-1">
                          <span className="block text-md font-semibold text-dark dark:text-white sm:text-[22px] md:text-md lg:text-[`8px] xl:text-xl">
                              Available now
                          </span>
                    <div className="px-2 flex text-start sm:pb-5 md:pb-2 xl:pb-5 flex-1">
                    <div>
                          <div className="py-2">
                              <img src={appstoreicon} alt="" className="h-[35px]" />
                            </div>
                          <div className="py-2">
                              <img src={googleplayicon} alt="" className="h-[35px]" />
                              </div>
                          <div className="py-2">
                              <img src={huaweiiconfrom} alt="" className="h-[35px]" />
                                  </div>
                                  </div>
                              <div className="flex-1 mt-auto text-end px-4">
                              <img src={Almosaferqrcode} alt="" className="h-[100px]" />
                          </div>
                          </div>
                          
                  </div>
                  <div className="px-2">
                      <img src={almfooterimageen} alt="" className="w-full h-[200px]" />
                      </div>
                      </div>
              </div>
              <div className="container-fluit px-8 bg-white flex">
                  <SubCardTitel
                      title="Looking for help?"
                      subtitle="Our team is available 24/7"
                  /><SubCard
                      title="+966112199500"
                      subtitle="Call us at:"
                      icon={icon24hour}
                  />
                  <SubCard
                      title="support@almosafer.com"
                      subtitle="Or you can email us at:"
                      icon={iconemail}
                  />
                  <SubCard
                      title="+966 55 440 0000"
                      subtitle="Get support via WhatsApp:"
                      icon={iconwhatsapp}
                  />
              </div>
          </section>
      
      </>
  );
};

export default UpFooter;

const SubCard = ({
  title,
  subtitle,
  icon,
}) => {
  return (
      <>
        <div className="flex-1 pt-5">
          <div className="flex border-l border-gray-400">
            <div className="px-3 pt-2 w-12 md:w-16">
                <img src={icon} alt="" className="" />
            </div>
                <div className="px-2 text-start">
                      <p className="text-base text-sm md:text-md leading-relaxed text-body-color dark:text-dark-6">
                          {subtitle}
                      </p>
                      <h3>
                          <span className="block text-sm font-semibold text-dark dark:text-white sm:text-[16px] md:text-md lg:text-[18px] xl:text-xl 2xl:text-[20px]">
                              {title}
                          </span>
                      </h3>
                  </div>
          </div>
        </div>
    </>
   );
};

const SubCardTitel = ({
  title,
  subtitle,
}) => {
  return (
      <>
        <div className="flex-1 py-5">
              <div className="px-2 text-start">
                  <h3>
                    <span className="block text-xl font-semibold text-dark dark:text-white sm:text-[16px] md:text-xl lg:text-[22px] xl:text-1xl 2xl:text-[22px]">
                        {title}
                    </span>
                   </h3>
                  <p className="text-base sm:text-sm md:text-md leading-relaxed text-body-color dark:text-dark-6">
                        {subtitle}
                  </p>
              </div>
        </div>
        
    </>
   );
};