import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HotelList from './components/HotelList';
import HotelRooms from './components/HotelRooms';
import RoomDetails from './components/RoomDetails';
import LoginPage from './components/LoginPage';
import OtherRoomDetails from './components/OtherRoomDetails';
import CompletDetailRoom from './pages/CompletDetailRoom';

const App = () => {
    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/" element={<HotelList />} />
                    <Route path="/hotels/:hotelId" element={<HotelRooms />} />
                    <Route path="/hotels/:hotelId/rooms/:roomId" element={<RoomDetails />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/CompletDetailRoom" element={<CompletDetailRoom />} />
                    <Route path="/OtherRoomDetails" element={<OtherRoomDetails />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
