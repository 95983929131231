import React from "react";
import img1 from "../assets/images/almosafer1.jpg"
import img2 from "../assets/images/almosafer2.webp"
import img3 from "../assets/images/almosafer3.webp"
import img4 from "../assets/images/almosafer4.webp"


const HotelGallery = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-1">
      {/* Large Image */}
      <div className="col-span-2 md:col-span-2">
        <img
          src={img1}
          alt="Hotel"
          className="w-full h-auto"
        />
        <div className="grid grid-cols-3 gap-1 mt-1">
          <img
          src={img2}
          alt="Hotel"
          className="w-full h-auto"
        />
        <img
          src={img3}
          alt="Hotel"
          className="w-full h-auto"
          />
        <img
          src={img1}
          alt="Hotel"
          className="w-full h-auto"
        />
        </div>
      </div>
      {/* Small Images */}
      <div className="grid grid-cols-2 gap-1 col-span-2 md:col-span-2">
        <img
          src={img2}
          alt="Hotel"
          className="w-full h-full"
        />
        <img
          src={img3}
          alt="Hotel"
          className="w-full h-full"
        />
        <img
          src={img4}
          alt="Hotel"
          className="w-full h-full"
        />
        <img
          src={img1}
          alt="Hotel"
          className="w-full h-full"
        />
      </div>
    </div>
  );
};

const HotelDetails = () => {
  return (
    <div className="px-4 py-8">
      <h1 className="text-3xl font-bold">Paramount Hotel Dubai</h1>
      <div className="flex items-center mt-2">
        <span className="text-yellow-500">★★★★★</span>
        <span className="ml-2 text-gray-700">Business Bay</span>
      </div>
      <div className="flex justify-between mt-4">
        <div className="text-lg">
          <span className="font-bold">8.4</span> Very good based on exclusive
          reviews
        </div>
        <div className="text-lg">
          <span className="font-bold">8.2</span> Very good based on 134 ratings
        </div>
      </div>
    </div>
  );
};

const BookingForm = () => {
  return (
    <div className="p-4 bg-gray-100 rounded-md mt-6">
      <form className="grid grid-cols-1 md:grid-cols-7 gap-4">
        <div className="col-span-2">
          <label className="block font-semibold">Check In</label>
          <input
            type="date"
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="col-span-2">
          <label className="block font-semibold">Check Out</label>
          <input
            type="date"
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="col-span-2">
          <label className="block font-semibold">Guests</label>
          <select className="w-full p-2 border border-gray-300 rounded-md">
            <option>1 Room, 2 Adults, 0 Children</option>
          </select>
        </div>
        <div className="flex justify-end items-center">
          <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
            Modify search
          </button>
        </div>
      </form>
    </div>
  );
};

const HotelPage = () => {
  return (
    <div className="container-fluit mx-auto p-4">
      <HotelGallery />
      <HotelDetails />
      <BookingForm />
    </div>
  );
};

export default HotelPage;
