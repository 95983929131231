import React from "react";

// Badge Component for showing tags like "Free cancellation", "Breakfast included"
const Badge = ({ label }) => (
  <span className="bg-gray-100 text-gray-600 px-2 py-1 rounded-full text-xs mr-2">
    {label}
  </span>
);

// RoomCard Component to display the room details
const RoomCard = () => {
  return (
    <div className="p-4 border border-gray-200 bg-white rounded-lg my-4">
      {/* Top section with badges */}
      <div className="flex mb-2">
        <Badge label="Free cancellation" />
        <Badge label="Breakfast included" />
        <Badge label="With view" />
      </div>

      {/* Room Information */}
      <div className="flex flex-col md:flex-row">
        {/* Room image and basic details */}
        <div className="flex md:w-1/3">
          <img
            src="https://via.placeholder.com/150"
            alt="Room"
            className="rounded-lg"
          />
          <div className="ml-4">
            <h3 className="text-xl font-bold">Standard Room (Scene)</h3>
            <p className="text-gray-500">2 Adults</p>
            <p className="text-gray-500">Size: 43m²</p>
            <a href="#" className="text-blue-500">
              More room info
            </a>
          </div>
        </div>

        {/* Room options and pricing */}
        <div className="mt-4 md:mt-0 flex-grow">
          <h4 className="text-lg font-semibold mb-2">Select an option</h4>
          <div className="border-t border-gray-300 py-4">
            {/* Option details */}
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
              <div className="text-sm text-gray-600">
                <p>FREE cancellation before 08 Oct 2024</p>
                <p>Pay later option available</p>
                <p>Room only</p>
              </div>

              {/* Pricing Details */}
              <div className="flex items-center mt-4 md:mt-0">
                <div className="text-right mr-4">
                  <p className="text-gray-500 line-through">$177</p>
                  <p className="text-red-600 font-bold text-lg">$167</p>
                  <p className="text-sm">Total for 1 night (incl. VAT)</p>
                </div>
                <button className="bg-red-500 text-white px-4 py-2 rounded-lg">
                  Book now
                </button>
              </div>
            </div>

            <div className="mt-2 text-sm text-gray-500">
              <p>1 room: 2 Adults</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RoomSelectionPage = () => {
  return (
    <div className="container-fluit mx-auto px-4">
      <RoomCard />
    </div>
  );
};

export default RoomSelectionPage;
