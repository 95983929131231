import React, { useState } from "react";
import AccountDropdown4 from './AccountDropdown4';
import PageTitle5 from './PageTitle5';
import ButtonHeader from './ButtonHeader';
import TabHeader from './TabHeader';
import { Link } from 'react-router-dom'; 

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <header className={`bg-my-primary dark:bg-dark text-white`}>
      <div className="w-full items-center container-fluit justify-between px-8">
        <div className="relative -mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <a href="/#" className="block w-full py-5 flex">
              {/* <img
                src="../../assets/images/logo/logo-primary.svg"
                alt="logo"
                className="dark:hidden"
                          /> */}
              <svg stroke="#fff" xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none" color="" size="24">
                          <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M2.25 16v-2.25a2 2 0 0 1 2-2h15.5a2 2 0 0 1 2 2V16m-19.5 3.5v-12a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3v12m0-3.25H2.25m2.25-4.5v-1a2 2 0 0 1 2-2H10a2 2 0 0 1 2 2m0 0v1m0-1a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v1">
                          </path>
                      </svg>
                  <span className="px-2">Stays</span>

              {/* <img
                src="/assets/images/logo/logo-white.svg"
                alt="logo"
                className="hidden dark:block"
              /> */}
            </a>
          </div>
          <div className="flex w-full items-center justify-between px-4">
            <div>
              <button
                onClick={() => setOpen(!open)}
                id="navbarToggler"
                className={` ${
                  open && "navbarTogglerActive"
                } absolute  bg-black right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden`}
              >
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
              </button>
              <nav
                // :className="!navbarOpen && 'hidden' "
                id="navbarCollapse"
                className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg px-6 py-5 shadow dark:bg-dark-2 lg:static lg:block lg:w-full lg:max-w-full lg:shadow-none lg:dark:bg-transparent ${
                  !open && "hidden"
                }  `}
              >
                
                <ul className="block lg:flex">
                  <ListItem><AccountDropdown4 /></ListItem>
                  <ListItem NavLink="/#">Home</ListItem>
                  <ListItem NavLink="/#">Payment</ListItem>
                  <ListItem NavLink="/#">About</ListItem>
                  <ListItem NavLink="/#">Blog</ListItem>
                </ul>
              </nav>
            </div>
            <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
             <Link to={`/login`}>
              <a
                href=""
                className="px-7 text-nowrap py-3 text-base font-medium text-white hover:text-dark-6 dark:text-white"
              >
                Sign in
              </a>

              <a
                href="/login"
                className="h-[32px] text-nowrap mt-2 rounded-md border border-white px-7 py-1 text-base font-medium text-white"
              >
                Sign Up
                </a>
                </Link>
            </div>
          </div>
        </div>
          </div>
          <div class="w-full items-center container-fluit pb-3 px-8">
              <nav class="flex w-full items-center ">
                  <a href="/en/hotels-home" data-testid="Header__HotelsNavigationTab" className="flex">
                      <svg stroke="#fff" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" color="" size="16">
                          <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M2.25 16v-2.25a2 2 0 0 1 2-2h15.5a2 2 0 0 1 2 2V16m-19.5 3.5v-12a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3v12m0-3.25H2.25m2.25-4.5v-1a2 2 0 0 1 2-2H10a2 2 0 0 1 2 2m0 0v1m0-1a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v1">
                          </path>
                      </svg>
                      <span className="px-2">Stays</span>
                  </a>
                  <a href="/en/flights-home" data-testid="Header__FlightsNavigationTab" className="px-4 flex">
                      <svg stroke="#fff" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" color="" size="16">
                          <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="m3.76 11.99-1.5-3.75h3l1.5 1.5h3l-1.5-7.5h3l3.75 7.5 4.5.01a2.25 2.25 0 0 1 0 4.5l-4.5.01-3.75 7.5h-3l1.5-7.5h-3l-1.5 1.48h-3l1.5-3.75Z">
                          </path>
                      </svg>
                      <span className="px-2">Flights</span>
                  </a>
              </nav>
          </div>
          <div className="w-full bg-hadder-img bg-cover bg-center">
              <PageTitle5 />
              <div className="w-full px-8">
                  <ButtonHeader />
              </div>
              <TabHeader/>
          </div>
          
          

    </header>
  );
};

export default Navbar;

const ListItem = ({ children, NavLink }) => {
  return (
    <>
      <li>
        <a
          href={NavLink}
          className="flex py-2 text-base font-medium text-white hover:text-dark-6 dark:text-dark-6 dark:hover:text-white lg:ml-12 lg:inline-flex"
        >
          {children}
        </a>
      </li>
    </>
  );
};
