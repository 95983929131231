import React from "react";
import img1 from "../assets/images/almosafer1.jpg"

const RoomCard = () => {
  return (
    <div className="mx-4 bg-white border rounded-lg shadow-md p-6 mt-4">
      <div className="flex flex-col md:flex-row">
        {/* Image section */}
        <div className="w-full md:w-1/3">
          <img
            className="rounded-lg object-cover w-full h-full"
            src={img1} // replace with actual image URL
            alt="Room"
          />
        </div>

        {/* Room details */}
        <div className="w-full md:w-2/3 pl-0 md:pl-6">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-2xl font-semibold">Silver Screen Suite - King Bed</h2>
          </div>
          <div className="flex space-x-4 mb-4 text-gray-500">
            <p>👥 2 Adults</p>
            <p>📏 Size: 99m<sup>2</sup></p>
          </div>
          <a href="#" className="text-blue-500 hover:underline mb-4 inline-block">
            More room info
          </a>

          {/* Booking options */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="font-semibold mb-2">Select an option</h3>
            <div className="flex justify-between items-center mb-2">
              <div>
                <p>Room only</p>
                <p className="text-sm text-green-500">FREE cancellation before 08 Oct 2024</p>
                <p className="text-sm text-gray-500">Pay later option available</p>
              </div>
              <div className="text-right">
                <p className="text-sm text-gray-500 line-through">$532</p>
                <p className="text-xl font-bold text-red-500">$500</p>
                <p className="text-sm text-gray-500">Total for 1 night (incl. VAT)</p>
              </div>
            </div>
            <button className="w-full bg-red-500 text-white py-2 rounded-lg hover:bg-red-600">
              Book now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
