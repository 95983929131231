import React from "react";
import { ItemWithPrograssBar, AvatarChar, ButtonRoundedlg } from './element_component/BadegsBlue';

const ReviewSection = () => {
  return (
    <div className="mx-auto p-6">
      <div className="flex flex-col md:flex-row gap-6">
        {/* Rating Summary max-w-5xl  */}
        <div className="w-full h-fit md:w-1/3 p-4 pb-10 border rounded-lg shadow-md bg-white">
          <div className="text-2xl font-bold mb-2 text-green-600">8.2</div>
          <p className="text-lg font-semibold text-gray-700">Very good</p>
          <p className="text-sm text-gray-500">based on 134 ratings</p>

          <div className="mt-4">
            {/* Rating Bars */}
            {[
              { label: "Amenities", value: 8.8 },
              { label: "Cleanliness", value: 8.8 },
              { label: "Comfort", value: 8.4 },
              { label: "Property Condition", value: 8.6 },
              { label: "Service", value: 3.2 },
            ].map((item, index) => (
              <>
              {/* <div key={index} className="flex items-center justify-between mb-2">
                <span className="text-sm font-medium text-gray-600">{item.label}</span>
                <div className="flex items-center w-1/2">
                  <div className="bg-gray-200 h-2 w-full rounded-lg relative">
                    <div
                      className="bg-green-500 h-2 rounded-lg"
                      style={{ width: `${(item.value / 10) * 100}%` }}
                    />
                  </div>
                  <span className="ml-2 text-sm text-gray-700">{item.value}</span>
                </div>
                </div> */}
                <ItemWithPrograssBar index={index} label={item.label} value={item.value} classname="bg-green-500"/>
                </>
            ))}
          </div>
        </div>

        {/* Guest Reviews */}
        <div className="w-full md:w-2/3 p-4 border rounded-lg shadow-md bg-white">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">All guest reviews</h2>
            <div className="flex space-x-2">
              {/* <button className="px-2 py-1 bg-teal-100 text-teal-600 rounded-lg">
                Newest
              </button> */}
              <ButtonRoundedlg titel="Newest" />
              <ButtonRoundedlg titel="Families" classname="px-2 py-1 bg-gray-100 text-gray-600"/>
              <ButtonRoundedlg titel="Couples" classname="px-2 py-1 bg-gray-100 text-gray-600"/>
              <ButtonRoundedlg titel="Friends" classname="px-2 py-1 bg-gray-100 text-gray-600"/>
              <ButtonRoundedlg titel="Solo" classname="px-2 py-1 bg-gray-100 text-gray-600"/>
              {/* <button className="px-2 py-1 bg-gray-100 text-gray-600 rounded-lg">
                Families
              </button>
              <button className="px-2 py-1 bg-gray-100 text-gray-600 rounded-lg">
                Couples
              </button>
              <button className="px-2 py-1 bg-gray-100 text-gray-600 rounded-lg">
                Friends
              </button>
              <button className="px-2 py-1 bg-gray-100 text-gray-600 rounded-lg">
                Solo
              </button> */}
            </div>
          </div>

          {/* Single Review */}
          <div className="border rounded-lg p-4 bg-gray-50">
            <div className="flex items-center mb-2">
              {/* <div className="bg-yellow-500 text-white rounded-full w-8 h-8 flex items-center justify-center">
                M
              </div> */}
              <AvatarChar titel="M" classname="bg-yellow-500 text-white"/>
              <div className="ml-3">
                <h3 className="font-medium text-gray-800">Mohammed</h3>
                <p className="text-sm text-gray-500">Saudi Arabia - Sep 2024</p>
                <p className="text-sm text-gray-500">Stayed with friends</p>
              </div>
            </div>
            <p className="text-gray-700 mb-2">
              It was an amazing hotel and would come back to it again.
            </p>
            <button className="text-teal-500 hover:underline">Translate</button>
            <div className="mt-2 text-right text-xl text-teal-500">10</div>
          </div>

          {/* Footer */}
          <p className="text-sm text-gray-400 mt-4">
            Review from <span className="font-bold text-teal-500">Almosafer</span> customer
          </p>

          {/* Single Review */}
          <div className="border rounded-lg mt-4 p-4 bg-gray-50">
            <div className="flex items-center mb-2">
              {/* <div className="bg-yellow-500 text-white rounded-full w-8 h-8 flex items-center justify-center">
                M
              </div> */}
              <AvatarChar titel="M" classname="bg-yellow-500 text-white"/>
              <div className="ml-3">
                <h3 className="font-medium text-gray-800">Mohammed</h3>
                <p className="text-sm text-gray-500">Saudi Arabia - Sep 2024</p>
                <p className="text-sm text-gray-500">Stayed with friends</p>
              </div>
            </div>
            <p className="text-gray-700 mb-2">
              It was an amazing hotel and would come back to it again.
            </p>
            <button className="text-teal-500 hover:underline">Translate</button>
            <div className="mt-2 text-right text-xl text-teal-500">10</div>
          </div>

          {/* Footer */}
          <p className="text-sm text-gray-400 mt-4">
            Review from <span className="font-bold text-teal-500">Almosafer</span> customer
          </p>

          {/* Single Review */}
          <div className="border rounded-lg mt-4 p-4 bg-gray-50">
            <div className="flex items-center mb-2">
              {/* <div className="bg-yellow-500 text-white rounded-full w-8 h-8 flex items-center justify-center">
                M
              </div> */}
              <AvatarChar titel="M" classname="bg-yellow-500 text-white"/>
              <div className="ml-3">
                <h3 className="font-medium text-gray-800">Mohammed</h3>
                <p className="text-sm text-gray-500">Saudi Arabia - Sep 2024</p>
                <p className="text-sm text-gray-500">Stayed with friends</p>
              </div>
            </div>
            <p className="text-gray-700 mb-2">
              It was an amazing hotel and would come back to it again.
            </p>
            <button className="text-teal-500 hover:underline">Translate</button>
            <div className="mt-2 text-right text-xl text-teal-500">10</div>
          </div>

          {/* Footer */}
          <p className="text-sm text-gray-400 mt-4">
            Review from <span className="font-bold text-teal-500">Almosafer</span> customer
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;
