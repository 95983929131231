import React from "react";
import img1 from "../assets/images/almosafer1.jpg"
import img2 from "../assets/images/almosafer2.webp"
import img3 from "../assets/images/almosafer3.webp"
import img4 from "../assets/images/almosafer4.webp"
import icon01 from "../assets/images/icon-01.svg"
import icon02 from "../assets/images/icon-02.svg"
import icon03 from "../assets/images/icon-03.svg"
import icon04 from "../assets/images/icon-04.svg"
import { BadgesItem } from './DangerBadge';
import RattingStare from './RattingStare';

import { Link, useParams } from 'react-router-dom';

const CardHotelDetaile = () => {
  return (
    <>
      <section className="bg-white pb-10 pt-4 dark:bg-dark lg:pb-8">
        <div className="container-fluit px-8">
           {/* <div className=" mb-8 max-w-[510px] text-start lg:mb-8">
              
              <h3 className="mb-2 text-xl font-bold leading-[1.2] text-dark dark:text-white sm:text-1xl md:text-[30px]">
                What We Offer
              </h3>
              <p className="text-base text-xl text-body-color dark:text-dark-6">
                There are many variations of passages of Lorem Ipsum.
              </p>
          </div> */}
          <div className="">
            <Link to={`/hotels/2/rooms/4`}>             
            <SingleCard
              image={img1}
              CardTitle="SLS Dubai Hotel & Residences"
              titleHref="/#"
              btnHref="/#"
              CardDescription="Lorem Lorem consectetur adipiscing."
              Button="View Details"
              title="Cairo"
              subtitle="Hotel"
              icon={icon01}
              />
            </Link>
            <Link to={`/hotels/2/rooms/4`}>
            <SingleCard
              image={img2}
              CardTitle="Creative Card Component "
              CardDescription="Lorem consectetur adipiscing elit."
              Button="View Details"
              title="Dubai"
              subtitle="Hotel"
              icon={icon02}
            />
            </Link>
            <Link to={`/hotels/2/rooms/4`}>
            <SingleCard
              image={img3}
              CardTitle="The ultimate UX and UI"
              CardDescription="Lorlit. Lorem  adipiscing elit."
              Button="View Details"
              title="Cairo"
              subtitle="Hotel"
              icon={icon03}
          />
            </Link>
            <Link to={`/hotels/2/rooms/4`}>
            <SingleCard
              image={img4}
              CardTitle="Component designs graphic elements"
              CardDescription="Lorem adipiscing elit. Lorem iscing elit."
              Button="View Details"
              title="Dubai"
              subtitle="Hotel"
              icon={icon04}
        />
        </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default CardHotelDetaile;
const SingleCard = ({
  image,
  Button,
  CardDescription,
  CardTitle,
  titleHref,
  btnHref,
  title,
  subtitle,
  icon,
}) => {
  return (
    <>
      <div className="mb-10 flex-1 flex-grow border rounded-xl p-2 bg-white flex flex-col md:flex-row overflow-hidden duration-300 hover:shadow-3 dark:bg-dark-2">
        {/* Image Section */}
        <div className="relative w-full md:w-56 flex-shrink-0">
          <img src={image} alt="" className="w-full h-56 md:h-[245px] rounded-xl object-cover" />
          <div className="absolute bottom-3 w-full text-center text-white">
            <div className="text-md font-semibold sm:text-[20px] md:text-md lg:text-[22px] xl:text-xl 2xl:text-[22px]">
              {title}
            </div>
            <div>{subtitle}</div>
          </div>
        </div>

        {/* Text Content Section */}
        <div className="flex-1 flex-grow h-full px-2 mt-4 md:mt-0 md:pb-2 xl:pb-5 text-start">
          <h3>
            <a
              href={titleHref ? titleHref : "/#"}
              className="mb-1 block text-md text-dark dark:text-white"
            >
              {CardTitle}
            </a>
          </h3>
          <div className="flex items-center">
            {/* Card Description or badges */}
            <BadgesItem roundedFull bgOpacity>
              {subtitle}
            </BadgesItem>
            <RattingStare />
          </div>
          <p className="my-2 text-sm text-black-1 leading-relaxed text-body-color dark:text-dark-6">
            {CardDescription}
          </p>
        </div>

        {/* Right Section */}
        <div className="flex-1 flex-grow-0 h-56 md:h-[245px] flex flex-col justify-between h-full  border-t md:border-t-0 md:border-l border-gray-300 h-full px-4 flex flex-col justify-between">
          <div className="flex justify-between">
            <div className="w-full">
              <div className="w-full text-md bold px-4 text-emerald-500 dark:text-dark-6">
                {title}
              </div>
              <div className="w-full px-4 text-sm leading-relaxed text-body-color dark:text-dark-6">
                {subtitle}
              </div>
            </div>
            <div className="relative">
              <svg color="#129911" viewBox="0 0 60 54" className="w-14 h-full">
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M31.039 42.668L19.497 53.563c-.964.91-2.684.304-2.684-.945v-9.95H2.273A2.273 2.273 0 0 1 0 40.395V2.273A2.273 2.273 0 0 1 2.273 0h55.454A2.273 2.273 0 0 1 60 2.273v38.122a2.273 2.273 0 0 1-2.273 2.273H31.04z"
                />
              </svg>
              <span className="absolute left-3 top-3">8.8</span>
            </div>
          </div>
          {Button && (
            <a
              href={btnHref ? btnHref : "#"}
              className="bg-blue-primary text-nowrap inline-block text-center w-full rounded-full border border-gray-300 px-7 py-2 text-base font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white dark:border-dark-3 dark:text-dark-6"
            >
              {Button}
            </a>
          )}
        </div>
      </div>
    </>
  );
};
