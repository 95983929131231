import React from 'react';
import { Link } from 'react-router-dom';
import { hotels } from '../data';
import Navbar from './Navbar';
import Footer from './Footer';
import UpFooter from './UpFooter';
import Service from './Service';
import Card from './Card';

const HotelList = () => {
    return (
         <div className="container-fluit mx-auto bg-gray-2">
            {/* <h1 className="text-3xl font-bold mb-6 text-center">Hotels</h1> */}
            <Navbar />
            <Link to={`/hotels/2`}>
            <Card />
                <Service />
                </Link>
            <div className="bg-gray-2 px-8 grid grid-cols-1 mb-8 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {hotels.map((hotel) => (
                    <div key={hotel.id} className="shadow-md rounded-lg overflow-hidden">
                        <div className="p-6">
                            <h2 className="text-2xl font-semibold mb-4">{hotel.name}</h2>
                            <Link to={`/hotels/${hotel.id}`} className="text-blue-500 hover:text-blue-700 font-bold">
                                View Rooms
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
            <UpFooter />
            <Footer/>
            </div>
    );
};

export default HotelList;
