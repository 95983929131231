import React, { useState } from "react";
import AccountDropdown4 from './AccountDropdown4';
import { Link } from 'react-router-dom'; 

const NavbarAll = () => {
  const [open, setOpen] = useState(false);

  return (
    <header className={`container-fluit w-full bg-my-primary dark:bg-dark text-white`}>
      <div className="w-full items-center container-fluit justify-between px-8">
        <div className="relative -mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <a href="/#" className="block w-full flex">
              {/* <img
                src="../../assets/images/logo/logo-primary.svg"
                alt="logo"
                className="dark:hidden"
                          /> */}
              <svg stroke="#fff" xmlns="http://www.w3.org/2000/svg"
                width="34" height="34"
                fill="none" color="" size="24">
                    <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M2.25 16v-2.25a2 2 0 0 1 2-2h15.5a2 2 0 0 1 2 2V16m-19.5 3.5v-12a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3v12m0-3.25H2.25m2.25-4.5v-1a2 2 0 0 1 2-2H10a2 2 0 0 1 2 2m0 0v1m0-1a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v1">
                      </path>
                </svg>
                  <span className="px-2">Stays</span>

              {/* <img
                src="/assets/images/logo/logo-white.svg"
                alt="logo"
                className="hidden dark:block"
              /> */}
            </a>
          </div>
          <div className="flex w-full items-center justify-between px-4">
            <div>
              <button
                onClick={() => setOpen(!open)}
                id="navbarToggler"
                className={` ${
                  open && "navbarTogglerActive"
                } absolute bg-my-primary right-1 top-1/2 block -translate-y-1/2 rounded-sm px-3 ring-primary focus:ring-2 lg:hidden`}
              >
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
              </button>
              <nav
                // :className="!navbarOpen && 'hidden' "
                id="navbarCollapse"
                className={`absolute bg-my-primary z-10 right-4 top-full w-full max-w-[250px] rounded-lg px-6 py-2 shadow dark:bg-dark-2 lg:static lg:block lg:w-full lg:max-w-full lg:shadow-none lg:dark:bg-transparent ${
                  !open && "hidden"
                } `}
              >
                
                <ul className="block lg:flex">
                  <ListItem><AccountDropdown4 /></ListItem>
                  <ListItem NavLink="/#">Home</ListItem>
                  <ListItem NavLink="/#">Payment</ListItem>
                  <ListItem NavLink="/#">About</ListItem>
                  <ListItem NavLink="/#">Blog</ListItem>
                </ul>
              </nav>
            </div>
            <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
              <Link to={`/login`}>
              <a
                href=""
                className="px-7 text-nowrap py-3 text-base font-medium text-white hover:text-dark-6 dark:text-white"
              >
                Sign in
              </a>

              <a
                href=""
                className="h-[32px] text-nowrap mt-2 rounded-md border border-white px-7 py-1 text-base font-medium text-white"
              >
                Sign Up
                </a>
                </Link>
            </div>
          </div>
        </div>
          </div>
          
          
          

    </header>
  );
};

export default NavbarAll;

const ListItem = ({ children, NavLink }) => {
  return (
    <>
      <li>
        <a
          href={NavLink}
          className="flex py-2 text-base font-medium text-white hover:text-dark-6 dark:text-dark-6 dark:hover:text-white lg:ml-12 lg:inline-flex"
        >
          {children}
        </a>
      </li>
    </>
  );
};
