import React from 'react';
import { useParams, Link } from 'react-router-dom'; // Import Link here
import { hotels } from '../data';
import Portfolio from './Portfolio';
import Team from './Team';
import Pricing from './Pricing';
import Footer from "./Footer";
import NavbarAll from "./NavbarAll";

const RoomDetails = () => {
    const { hotelId, roomId } = useParams();
    const hotel = hotels.find((h) => h.id === parseInt(hotelId));
    const room = hotel ? hotel.rooms.find((r) => r.id === parseInt(roomId)) : null;

    if (!hotel || !room) {
        return <h2 className="text-center text-red-500">Room not found</h2>;
    }

    return (
        <>
            <NavbarAll/>
        {/* <div className="container mx-auto px-4 py-8">
            <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
                <div className="p-6">
                    <h1 className="text-3xl font-bold mb-4">{room.name}</h1>
                    <p className="text-gray-700 mb-4">{room.description}</p>
                    <Link to={`/hotels/${hotelId}`} className="text-blue-500 hover:text-blue-700 font-bold">
                        Back to Hotel
                    </Link>
                </div>
            </div>
            </div> */}
           <Link to={`/CompletDetailRoom`}>
            <Portfolio />
            <Team />
                <Pricing />
                </Link>
            <Footer/>
        </>
    );
};

export default RoomDetails;
