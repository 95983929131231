export const hotels = [
    {
        id: 1,
        name: 'Hotel Sunshine',
        rooms: [
            { id: 1, name: 'Deluxe Room', description: 'A spacious deluxe room with a sea view.' },
            { id: 2, name: 'Suite Room', description: 'A luxury suite with a private balcony.' }
        ]
    },
    {
        id: 2,
        name: 'Mountain Resort',
        rooms: [
            { id: 3, name: 'Standard Room', description: 'A cozy standard room with a mountain view.' },
            { id: 4, name: 'Family Room', description: 'A large family room with two queen-size beds.' }
        ]
    }
];
