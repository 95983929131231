import React from "react";
import { useParams, Link } from 'react-router-dom';
import HotelPage from '../custom_component/HotelPage';
import NavbarAll from '../components/NavbarAll';
import Footer from '../components/Footer';
import RoomSelectionPage from '../custom_component/RoomSelectionPage';
import Room from '../custom_component/Room';
import RoomCard from '../custom_component/RoomCard';
import RoomForm from '../custom_component/RoomForm';
import ReviewSection from '../custom_component/ReviewSection';
import AmenitiesSection from '../custom_component/AmenitiesSection';
import HotelList from '../custom_component/HotelList';

const CompletDetailRoom = () => {
    return (
        <>
            <NavbarAll />
            <div className="bg-gray-200 pb-4">
            <Link to={`/CompletDetailRoom`}>
                <HotelPage />
                    <Room />
                    <div className="mx-4 border border-gray-300 rounded-lg">
                        <h1 className="px-6 pt-6 text-2xl font-semibold">Hilton Dubai Al Habtoor City</h1>
                        <RoomCard />
                        {/* <RoomForm/> */}
                        <RoomSelectionPage />
                    </div>
                    <div className="mx-4 border border-gray-300 rounded-lg">
                        <h1 className="px-6 pt-6 text-2xl font-semibold">Hilton Dubai Al Habtoor City</h1>
                        <RoomCard/>
                        <RoomSelectionPage />
                    </div>
                    <ReviewSection />
                    <AmenitiesSection />
                    <div className="m-4 my-12 bg-white border border-gray-300 rounded-2xl">
                        <HotelList />
                    </div>
                   
                
                    {/* <RoomCard />
                    <RoomForm/> */}
                </Link>
                </div>
            
            <Footer/>
        </>
    );
}
export default CompletDetailRoom;