import React from 'react';
import NavbarAll from './NavbarAll';
import Contact from './Contact';
import Footer from "./Footer";

const LoginPage = () => {

    return (
        <>
        <NavbarAll/>
            <Contact/>
           <Footer/>
            </>
    );
};

export default LoginPage;
