import React, { useState } from 'react'
import Checkbox2 from './Checkbox2';
const SideBar = () => {

  const [isOpen, setIsOpen] = useState(false);
 const [checkboxes, setCheckboxes] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
    option5: false,
 });
   const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
 const handleCheckboxChange = (event) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
    <button
        className="md:hidden fixed top-6 left-0 z-50 bg-black text-white p-2 rounded"
        onClick={toggleSidebar}
      >
        {/* Hamburger Icon */}
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        )}
      </button>
   
    <aside className={`fixed md:relative top-0 left-0 z-40 transition-transform duration-300 border-e bg-white shadow-2 shadow-slate-600 text-black p-6 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 w-72 md:w-80`}>
          <img
            src="https://via.placeholder.com/150"
            alt="Room"
            className="rounded-xl w-full h-32"
          />
      <h2 className="text-lg font-semibold my-4">Filter</h2>
      <div className="flex overflow-hidden bg-white rounded-xl mb-2 border">
        <input placeholder="Search property name..." data-testid=""
          className="border-l-xl ps-2 flex-1 form-control" value="" />
          <button data-testid="" type="button"
            className="rounded-r-xl bg-blue-primary p-2 btn btn-secondary">
            <svg focusable="false" color="inherit" fill="currentcolor"
              aria-hidden="true"
              role="presentation"
            viewBox="0 0 24 24"
            preserveAspectRatio="xMidYMid meet"
            width="24px" height="24px"
            className="sc-bxivhb wwKZm">
            <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
            </path>
            <path d="M0 0h24v24H0z" fill="none">
            </path></svg>
        </button>
      </div>
        <div className="space-y-4 text-md">
        <div className='border-b my-4 border-black'>
            {/* <h2 className='my-2'>ggg jhu klio </h2> */}
            <div className='flex justify-between mb-4'>
            <h2 className="text-md font-medium">Property Type</h2> <span className='bg-blue-primary text-white px-2 flex items-center'>Rest</span>
            </div>
          <div className='ps-1'>
          <div className='flex justify-between items-center mb-1'>
            <Checkbox2 classname="black"/> <span className="text-gray-600">78</span>
          </div>
          <div className='flex justify-between items-center mb-1'>
            <Checkbox2 classname="black"/> <span className="text-gray-600">39</span>
          </div>
          <div className='flex justify-between items-center mb-1'>
            <Checkbox2 classname="black"/> <span className="text-gray-600">55</span>
          </div>
          <div className='flex justify-between items-center mb-1'>
            <Checkbox2 classname="black"/> <span className="text-gray-600">8</span>
          </div>
          <div className='flex justify-between items-center mb-1'>
            <Checkbox2 classname="black"/> <span className="text-gray-600">112</span>
          </div>
         
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
            </div>
          </div>
            
        </div>

        <div className='border-b my-4 border-black'>
          <h2 className='my-2'>ggg jhu klio </h2>
          <div className='ps-2'>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
            </div>
          </div>
            
        </div>

        <div className='border-b my-4 border-black'>
          <h2 className='my-2'>ggg jhu klio </h2>
          <div className='ps-2'>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
            </div>
          </div>
            
        </div>
        <div className='border-b my-4 border-black'>
          <h2 className='my-2'>ggg jhu klio </h2>
          <div className='ps-2'>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
          </div>
          <div className='mb-2'>
            <Checkbox2 classname="black"/>
            </div>
          </div>
            
        </div>

        </div>
      </aside>
       {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
       </>
  )
}

export default SideBar;
