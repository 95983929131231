import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { hotels } from '../data';
import NavbarAll from './NavbarAll';
import CardHotelDetaile from './CardHotelDetaile';
import SideBar from './SideBar';
import Footer from "./Footer";
import { ButtonRoundIcon, Prograph } from '../custom_component/element_component/BadegsBlue';


const HotelRooms = () => {
    const { hotelId } = useParams();
    const hotel = hotels.find((h) => h.id === parseInt(hotelId));

    if (!hotel) {
        return <h2 className="text-center text-red-500">Hotel not found</h2>;
    }

    return (
        <>
            <NavbarAll />
            <div className='m-4'>
             <Prograph titel=" rooms, Please try changing the dates to see room availability." classname="text-sm mb-4">
                </Prograph>
                </div>
            <div className='flex container-fluit bg-white'>
                <SideBar />
                <div className='flex-grow'>
                    <div className='container-fluit flex px-8 py-2'>
                        <ButtonRoundIcon titel="assstggggggg" classname='border border-button-border-naice text-button-border-naice px-2 py-1 bg-button-bg-naice' >
                            <svg focusable="false" color="inherit" fill="currentcolor" aria-hidden="true" role="presentation" viewBox="0 0 150 150" preserveAspectRatio="xMidYMid meet" size="16" width="16" height="16" class="sc-gtfDJT frHOPR sc-bxivhb dcnttA">
                                <path d="M128.1 22C98.8-7.3 51.3-7.3 22 21.9-7.3 51.2-7.3 98.7 21.9 128c29.3 29.3 76.8 29.3 106.1.1l.1-.1c29.2-29.3 29.2-76.7 0-106zm-23 83c-3.5 3.5-9 3.5-12.4 0L75.1 87.4l-17.6 17.5c-3.4 3.4-9 3.4-12.4 0s-3.4-9 0-12.4L62.6 75 45.1 57.4c-3.4-3.4-3.4-9 0-12.4s9-3.4 12.4 0l17.6 17.6L92.6 45c3.4-3.4 9-3.4 12.4 0s3.4 9 0 12.4L87.4 75 105 92.6c3.5 3.4 3.5 8.9.1 12.4z">
                                </path>
                            </svg>
                        </ButtonRoundIcon>
                        <ButtonRoundIcon titel="tttb" classname='border border-button-border-naice text-button-border-naice shadow-button-box-shadow px-2 py-1 bg-button-bg-naice' >
                            <svg focusable="false" color="inherit" fill="currentcolor" aria-hidden="true" role="presentation" viewBox="0 0 150 150" preserveAspectRatio="xMidYMid meet" size="16" width="16" height="16" class="sc-gtfDJT frHOPR sc-bxivhb dcnttA">
                                <path d="M128.1 22C98.8-7.3 51.3-7.3 22 21.9-7.3 51.2-7.3 98.7 21.9 128c29.3 29.3 76.8 29.3 106.1.1l.1-.1c29.2-29.3 29.2-76.7 0-106zm-23 83c-3.5 3.5-9 3.5-12.4 0L75.1 87.4l-17.6 17.5c-3.4 3.4-9 3.4-12.4 0s-3.4-9 0-12.4L62.6 75 45.1 57.4c-3.4-3.4-3.4-9 0-12.4s9-3.4 12.4 0l17.6 17.6L92.6 45c3.4-3.4 9-3.4 12.4 0s3.4 9 0 12.4L87.4 75 105 92.6c3.5 3.4 3.5 8.9.1 12.4z">
                                </path>
                            </svg>
                        </ButtonRoundIcon>
                    </div>
                    <div className='container-fluit flex px-8 mt-4 py-2'>
                        <h3>Sort by:  </h3>
                        <ButtonRoundIcon titel="assst ggggggg" classname='border border-button-border-naice text-button-border-naice px-2 py-1 bg-button-bg-naice'
                        />  
                        <ButtonRoundIcon titel="tttb hikh uuttu ouou" classname='border border-button-border-naice text-button-border-naice shadow-button-box-shadow px-2 py-1 bg-button-bg-naice'
                        /> 
                        <ButtonRoundIcon titel="aseel flkk knmk" classname='border border-button-border-naice text-button-border-naice shadow-button-box-shadow px-2 py-1 bg-button-bg-naice'
                        /> 
                        <ButtonRoundIcon titel="tttb hjyhi uuhio hou" classname='border border-button-border-naice text-button-border-naice shadow-button-box-shadow px-2 py-1 bg-button-bg-naice'
                        />
                    </div>
                    
            <CardHotelDetaile />
        <div className="container-fluit bg-white mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center">Rooms in {hotel.name}</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {hotel.rooms.map((room) => (
                    <div key={room.id} className="bg-white shadow-md rounded-lg overflow-hidden">
                        <div className="p-6">
                            <h2 className="text-2xl font-semibold mb-4">{room.name}</h2>
                            <Link to={`/hotels/${hotelId}/rooms/${room.id}`} className="text-blue-500 hover:text-blue-700 font-bold">
                                View Room Details
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
                    </div>
                    </div>
            </div>
            <Footer/>
            </>
    );
};

export default HotelRooms;
