import React from "react";

const RattingStare = () => {
  return (
    <>
      <section class="">
      <div class="container">
        <div class="flex items-center gap-2">
          <div class="flex items-center gap-[5px]">
            <span>
              <svg
                width="18"
                height="18"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2498_240)">
                  <path
                    d="M14.925 5.975L10.4 5.275L8.34999 0.975C8.19999 0.675 7.79999 0.675 7.64999 0.975L5.59999 5.3L1.09999 5.975C0.774991 6.025 0.649991 6.45 0.899991 6.675L4.17499 10.05L3.39999 14.775C3.34999 15.1 3.67499 15.375 3.97499 15.175L8.04999 12.95L12.1 15.175C12.375 15.325 12.725 15.075 12.65 14.775L11.875 10.05L15.15 6.675C15.35 6.45 15.25 6.025 14.925 5.975Z"
                    fill="#FFA645"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2498_240">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span>
              <svg
                width="18"
                height="18"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2498_240)">
                  <path
                    d="M14.925 5.975L10.4 5.275L8.34999 0.975C8.19999 0.675 7.79999 0.675 7.64999 0.975L5.59999 5.3L1.09999 5.975C0.774991 6.025 0.649991 6.45 0.899991 6.675L4.17499 10.05L3.39999 14.775C3.34999 15.1 3.67499 15.375 3.97499 15.175L8.04999 12.95L12.1 15.175C12.375 15.325 12.725 15.075 12.65 14.775L11.875 10.05L15.15 6.675C15.35 6.45 15.25 6.025 14.925 5.975Z"
                    fill="#FFA645"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2498_240">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span>
              <svg
                width="18"
                height="18"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2498_240)">
                  <path
                    d="M14.925 5.975L10.4 5.275L8.34999 0.975C8.19999 0.675 7.79999 0.675 7.64999 0.975L5.59999 5.3L1.09999 5.975C0.774991 6.025 0.649991 6.45 0.899991 6.675L4.17499 10.05L3.39999 14.775C3.34999 15.1 3.67499 15.375 3.97499 15.175L8.04999 12.95L12.1 15.175C12.375 15.325 12.725 15.075 12.65 14.775L11.875 10.05L15.15 6.675C15.35 6.45 15.25 6.025 14.925 5.975Z"
                    fill="#FFA645"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2498_240">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span>
              <svg
                width="18"
                height="18"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2498_240)">
                  <path
                    d="M14.925 5.975L10.4 5.275L8.34999 0.975C8.19999 0.675 7.79999 0.675 7.64999 0.975L5.59999 5.3L1.09999 5.975C0.774991 6.025 0.649991 6.45 0.899991 6.675L4.17499 10.05L3.39999 14.775C3.34999 15.1 3.67499 15.375 3.97499 15.175L8.04999 12.95L12.1 15.175C12.375 15.325 12.725 15.075 12.65 14.775L11.875 10.05L15.15 6.675C15.35 6.45 15.25 6.025 14.925 5.975Z"
                    fill="#FFA645"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2498_240">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span>
              <svg
                width="18"
                height="18"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.02499 15.55C3.79999 15.55 3.57499 15.475 3.39999 15.35C3.04999 15.1 2.84999 14.65 2.92499 14.225L3.57499 10.2L0.774989 7.325C0.474989 7.025 0.374989 6.575 0.499989 6.15C0.624989 5.75 0.974989 5.45 1.37499 5.4L5.24999 4.775L6.99999 1.1C7.19999 0.699997 7.57499 0.449997 7.99999 0.449997C8.42499 0.449997 8.82499 0.699997 8.99999 1.1L10.75 4.75L14.6 5.35C15 5.425 15.35 5.7 15.475 6.1C15.625 6.525 15.5 6.975 15.2 7.275L12.425 10.175L13.075 14.225C13.15 14.675 12.975 15.1 12.6 15.35C12.25 15.6 11.825 15.625 11.45 15.425L7.99999 13.55L4.54999 15.425C4.39999 15.525 4.19999 15.55 4.02499 15.55ZM1.57499 6.5C1.57499 6.5 1.57499 6.525 1.57499 6.55L4.49999 9.55C4.67499 9.725 4.74999 10 4.72499 10.25L4.04999 14.425C4.04999 14.425 4.04999 14.425 4.04999 14.45L7.64999 12.5C7.87499 12.375 8.14999 12.375 8.39999 12.5L12 14.45C12 14.45 12 14.45 12 14.425L11.325 10.225C11.275 9.975 11.375 9.725 11.55 9.525L14.475 6.525C14.5 6.5 14.475 6.475 14.475 6.475L10.45 5.85C10.2 5.8 9.97499 5.65 9.87499 5.4L7.99999 1.6L6.19999 5.425C6.09999 5.65 5.87499 5.825 5.62499 5.875L1.57499 6.5Z"
                  fill="#FFA645"
                />
              </svg>
            </span>
          </div>
          {/* <p class="text-base text-dark-2 dark:text-gray-1">(55) Reviews</p> */}
        </div>
      </div>
    </section>
    </>
  );
};

export default RattingStare;
